<template>
  <div class="design">
    <div class="banner">
      <div class="banner_text">
        <h6 class="banner_title">研究方案设计指导</h6>
        <div class="line"></div>
        <p class="banner_content">RESEARCH PROGRAM DESIGN GUIDANCE</p>
      </div>
    </div>
    <div>
      <img class="pc-img" src="../assets/designImages/design-pc.jpg" alt="">
      <img class="mobile-img" src="../assets/designImages/design-mobile.jpg" alt="">
      <!-- <div class="container_text">
        研究方案的设计，是临床研究成功的基石。规范合理的方案设计可以使临床研究高效有序推进鸿创医学汇聚各学科专业人才，为您的研究方案提供科学的建议和指导
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <img src="../assets/designImages/design1.png" class="card-img-top">
            <div class="card-body">
              <h5 class="card-title">基于临床，反哺临床</h5>
              <p class="card-text"><small class="text-muted">以解决临床问题为起点，结合临床资源，设计有临床意义和科研价值的研究方案。</small></p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <img src="../assets/designImages/design2.png" class="card-img-top">
            <div class="card-body">
              <h5 class="card-title">专家评估，科学规划</h5>
              <p class="card-text"><small class="text-muted">各学科专家对您的方案进行专业评估，为您设计可行性高、临床价值高的研究方案。</small></p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <img src="../assets/designImages/design3.png" class="card-img-top">
            <div class="card-body">
              <h5 class="card-title">全面考量，优中选优</h5>
              <p class="card-text"><small class="text-muted">参考国内外专业相关文献结合实际条件，为您生成可行性创新性兼顾的最佳研究方案。</small></p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 小图片轮播 -->
    <!-- <div class="thumbnails">
      <img v-for="(image, index) in images" :key="index" :src="image.src" :alt="image.alt"
        :class="{ 'thumbnail': true, 'active': index === activeIndex }" @click="changeActiveImage(index)" />
    </div>
    <div class="main-image">
      <img :src="activeImage.src" :alt="activeImage.alt" />
    </div> -->
  </div>
</template>

<script>
// import Viewer from 'viewerjs'; // 引入图片查看器组件

export default {
  data() {
    return {
      activeIndex: 0,
      // images: [
      //   { src: '@/assets/1.png', alt: 'Image 1' },
      //   { src: '@/assets/2.png', alt: 'Image 2' },
      //   { src: '@/assets/3.png', alt: 'Image 3' },
      //   { src: '@/assets/4.png', alt: 'Image 4' }
      // ]
    };
  },

  computed: {

  },
  methods: {
    // changeActiveImage(index) {
    //   this.activeIndex = index;
    // },

  },
  mounted() {

  }
};
</script>

<style scoped lang="less">
/* 移动端样式 */
@media screen and (max-width: 767px) {

  .banner {
    width: 100%;
    height: 320px;
    background-image: url(../assets/designImages/Design_bannerMobile.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .banner_text {
      width: 385px;
      height: 200px;
      text-align: center;

      .banner_title {
        font-size: 48px;
      }

      .banner_content {
        font-size: 20px;
        color: #333333
      }

      .line {
        position: relative;
        top: 2%;
        left: 44%;
        width: 30px;
        height: 2px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        // 线条粗细
        border: 1px solid black;
        margin: 24px 0px;
      }

    }
  }

  .pc-img {
    display: none;
    /* 隐藏 PC 图片 */
  }

  .mobile-img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }
}

/* PC端样式 */
@media screen and (min-width: 768px) {
  .design {
    height: 1520px;

    .banner {
      width: 100%;
      height: 600px;
      background-image: url(../assets/designImages/design_banner.jpg);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .banner_text {
        width: 385px;
        height: 200px;
        text-align: center;

        .banner_title {
          font-size: 48px;
        }

        .banner_content {
          font-size: 20px;
          color: #333333;
        }

        .line {
          position: relative;
          top: 2%;
          left: 44%;
          width: 30px;
          height: 2px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          // 线条粗细
          border: 1px solid black;
          margin: 24px 0px;
        }

      }
    }

    div {
      text-align: center;

      /* 让内部内容在 PC 端水平居中显示 */
      .pc-img {
        display: inline-block;
        /* 设置为内联块元素，以便水平居中 */
        max-width: 100%;
        /* 图片宽度最大不超过父元素宽度 */
        height: auto;
        /* 让图片等比缩放 */
      }

      .mobile-img {
        display: none;
        /* 隐藏移动端图片 */
      }
    }


  }



}</style>
